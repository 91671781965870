<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 ghd-base-color-black"
        >
          <div
            class="d-flex align-center justify-center h-full"
          >
            <!-- triangle bg -->
            <img
              height="auto"
              width="auto"
              :src="appLogo"
            />
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0 ghd-base-color-white"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card
                flat
                class="ghd-base-color-white ghd-border-black"
              >
                <v-card-text>
                  <span class="text-large ghd-ITCAvant text-black text-uppercase">
                    Recupero password
                  </span>
                </v-card-text>
                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="forgotPasswordForm"
                    @submit.prevent="handleSubmitForm"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-4"
                      :error-messages="messages.email"
                      :rules="[validators.required, validators.emailValidator]"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                    >
                      Reset password
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Torna al login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'
import axios from '@axios'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'

export default {
  setup() {
    const email = ref('')
    const { router } = useRouter()
    const forgotPasswordForm = ref(null)

    const messages = computed(() => store.getters['global/getErrorMessages'])

    const handleSubmitForm = () => {
      const isFormValid = forgotPasswordForm.value.validate()

      if (!isFormValid) return

      const linkToResetPassword = router.resolve({ name: 'auth-reset-password' }).href

      axios.post('api/emails/reset-password', { email: email.value, return_url: btoa(`${window.location.origin + linkToResetPassword}/`) }).then().catch()
    }

    return {
      email,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
      messages,

      validators: {
        required,
        emailValidator,
      },

      handleSubmitForm,
      forgotPasswordForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
